import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en: {
				translations: {
					mainPage: {
						title: "Mercedes-Benz Trucks Operating Fluids",
						description: "Discover all tested and approved operating fluids for Actros, Atego, Arocs, and many other models from Mercedes-Benz Trucks | A service of Daimler Truck AG.",
						keywords: "operating fluids, engine oil, transmission oil,  Daimler Truck AG, DTFR number, coolant, e-drive systems, maintenance, transmission oils, approved engine oils, LKW und Bus-Bereich, Trucks, Buses, Actros, Atego, Arocs",
						author: " Daimler Truck AG",
						robots: "index, follow"
						},
					categoryOverview: {
							title: "Fluid Categories",
							description: "In addition to common categories like engine oil, transmission oil, and coolant, you will also find other approved products, e.g. for e-drive systems or maintenance.",
						},
					MbApprovalLabel: `MB-Approval/MB-Freigabe`,
					SheetNumber: `Sheet number`,
					Here: `here`,
					MailFeedbackPartOne: `Your message has been sent successfully.

          You will be redirected to the home page, in `,
					MailFeedbackPartTwo: ` seconds. 
          If you are not automatically redirected, please click `,
					CookieBarHeader: 'Mercedes-Benz AG uses cookies for various purposes',
					CookieBarContent:
						'We want to ensure optimum use of our website for you, and also to continually improve our website. You can revoke your voluntary consent at any time. You can find further information and setting options under "Settings".',
					CookieBarDetails: 'Show details',
					TechnicallyNeccessary: 'Only technically necessary',
					Analytics: 'Analysis and statistics',
					SaveSelected: 'Agree to selected',
					AgreeToAll: 'Agree to all',
					WelcomeToSupport: 'Contact',
					SupportInfo:
						'Do you have any questions or suggestions for improvement related to one of our apps or websites? Our customer support team is ready to help you. Please send us your queries or suggestions and we will ensure that they are actioned correctly.',
					SupportAsterisk:
						'Please complete all the fields marked with an asterisk (*).',
					FirstName: 'Your first name*',
					LastName: 'Your last name*',
					StreetName: 'Street name',
					HouseNumber: 'Nr',
					PostalCode: 'Postal code*',
					City: 'City',
					Country: 'Country or Region*',
					Email: 'Email',
					Message: 'Message',
					SupportPolicy:
						'I agree to my details being transferred and used by the app or website provider, its group of companies and or technical developer for the purpose of answering my query. ',
					Send: 'Send',
					Title: 'Subject',
					ErrorFirstName: 'Please enter a first name',
					ErrorLastName: 'Please enter a last name',
					ErrorEmail: 'Please enter an e-mail address',
					ErrorMessage: 'Please enter a request',
					ErrorTitle: 'Please enter a subject',
					ErrorCountry: 'Please enter a country',
					ErrorPostalCode: 'Please enter a postal code',
					LastUpdate: 'Last Update: ',
					ChoooseBeVo:
						'Please select the operating fluid that you are interested in:',
					Link1:
						'https://www.mercedes-benz.com/en/vehicles/service-parts/the-only-oil-with-mercedes-benz-know-how/',
					Link2:
						'https://www.mercedes-benz.de/passengercars/services-accessories/manuals.html',
					SearchResultPart1: 'Your search returned',
					SearchResultPart2: 'results',
					BackToOverview: '← Back to the overview',
					SearchLabel: 'Your search term',
					ProductName: 'Product name',
					ShowMore: 'Show more',
					ShowLess: 'Show less',
					NoProduct: 'No products available',
					PrologGearOil: `Gear oil is an important operating fluid for the function and durability of the transmission. Mercedes-Benz-Trucks gear oils are specially developed and tested for use in Mercedes-Bent-Trucks vehicles. Therefore, only gear oils with “DTFR xxxxxx” are recommended for use in Mercedes-Benz-Trucks vehicles. The “DTFR xxxxxx” of the products can be recognised by the indication (f.e. DTFR 13A100) on the package. All products approved by Daimler Truck AG for the respective gear oil specification are listed on the DTFR-Sheets 13xxxx and 12xxxx.
The axle and gear oil specifications approved for Mercedes-Benz-Trucks vehicles can be found in the respective owner’s manual and, if applicable, additional in the service information. 
 `,
					PrologFuel: `Fuels produced by highly developed chemical-physical processes using petroleum or renewable sources are energy sources for combustion engines in motor vehicles. These fuels are complex mixtures of compounds made up of carbon, hydrogen, and possibly oxygen with various structures that are adapted to the combustion processes of engines. Their energy density is high compared to other combustible fluids of the same weight. The energy stored is converted into mechanical energy during combustion in the engine.
Viewed globally, there is an extremely varying range of quality. Fuel quality plays a key role in a vehicle’s performance and exhaust gas emissions. Consequently, fuel quality should be adequately harmonized with a vehicle’s requirements. To achieve these goals, the global automotive industry has created the "World Wide Fuel Charter" ("WWFC"), which defines suitable target specifications for fuel properties with respect to the various exhaust gas and CO2 requirements. The WWFC is revised and adapted to the latest technology at regular intervals. The market quality of the fuels is usually defined by national standards.

<strong>Spark-Ignition Engine Fuel – Gasoline</strong> 
The term “gasoline” is normally used to describe the lighter crude oil fractions that are separated from the heavy fractions during the refining process. From a chemical perspective, gasoline consists of organic molecules within a certain boiling range (~40–200 °C). These molecules mainly consist of carbon and hydrogen atoms. Crude oil also contains sulfur, which needs to be reduced or removed to produce gasoline that will not damage the engine or other vehicle technologies. The ignition of spark-ignition engine fuels is realized via an external ignition source (e.g. spark plug).
The main classification of gasoline takes place based on knock resistance, which is represented using a research and motor octane number. Typical examples of these specifications are RON 95 and RON 98. However, in European and other markets, ethanol content with respect to vehicle compatibility is also an important parameter in addition to knock resistance. Details of fuels suitable for your vehicle can be found in the operator’s manual and on the inside of the fuel filler flap.

<strong>Diesel Fuel</strong> 
“Diesel fuel” is usually referred to as the middle distillate crude oil fraction that is separated from lighter and heavier fractions during the refining process. From a chemical perspective, diesel fuel consists of organic molecules within a certain boiling range (~160–350 °C). These molecules mainly consist of carbon and hydrogen atoms. Certain straight-chain molecules require modification for specific cooling properties. Crude oil also contains sulfur, which needs to be reduced or removed to produce diesel that will not damage the engine or other vehicle technologies. In Europe, the maximum volume of bio diesel permitted in diesel fuel is 7%. Details of fuels suitable for your vehicle can be found in the operator’s manual and on the inside of the fuel filler flap.

<strong>Paraffinic Diesel Fuels</strong> 
Paraffinic fuels are made almost entirely from alkanes and are therefore free from aromatics and sulfur. In addition to linear and branched alkanes, some fuels also contain cycloalkanes. The properties of paraffinic fuels differ from those of regular diesel fuel because of their chemical composition. The main differences between paraffinic diesel fuels and conventional diesel fuels are their higher cetane number and lower density. Details of fuels suitable for your vehicle can be found in the operator’s manual and on the inside of the fuel filler flap.

<strong>Fuel Additives and Secondary Additives</strong> 
Due to the high demands placed on fuels, it is necessary to optimize certain properties of basic fuels. Additives can provide additional properties to fuels while also accentuating or reducing existing properties. Additives are fuel-soluble compounds of a predominantly organic-chemical nature. The correct selection, application, and metering of these additives requires in-depth investigations in laboratories, on test stands, and in vehicles as to optimally adapt the effect the additives have on the respective fuel, ensure additives are harmonized with each other, and that there are no negative side effects. Because the consumer does not usually possess the necessary prerequisites for this, a fuel additive can be commissioned only by the fuel manufacturer. 
Nevertheless, fuel additives are constantly offered to drivers, some of which promise significant improvements such as improved engine performance with lower fuel consumption. For the sake of clarity, we refer to these additives as “secondary additives.” Since standardized and adequate fuel quality can be expected in most cases, secondary additives are generally not required in our automotive engines. It must be ensured that only the fuel quality specified by us is used in each case. In contrast, the use of secondary additives is generally only an additional cost that in the worst case may lead to lasting damage. Additional additives may be required in certain markets where poor fuel quality is the norm. In this special case, only additives that have been tested and approved by Mercedes-Benz-Trucks specialist workshops are recommended. The instructions and mixing ratios indicated on the container must be observed. 
The application of secondary additives without Mercedes-Benz-Trucks approval is strongly discouraged. Because their use may negatively affect the warranty of both the vehicle manufacturer and the fuel supplier, this is always to be carried out under the responsibility of the vehicle operator.`,
					PrologCoolant: `Coolants:
- ensure material compatibility
- enable optimal heat dissipation
- protect against corrosion
The corrosion/antifreeze or coolants approved in the specifications for operating fluids meet the demanding Mercedes-Benz-Trucks specifications. This ensures excellent operation of the cooling systems under the vehicle-specific loads.
It is not only the choice of coolant that is decisive, but also maintaining the optimal water quality and mixing concentration between concentrate and water during operation: too much additive package is just as unhelpful as is too much water.
DTFR-Sheet 29A100 contains general information on the correct handling of coolants.
DTFR-Sheet 29A110 lists all products approved by Mercedes-Benz-Trucks for the respective application.
`,
					PrologAdBlue: `Nox reducing agent AUS 32 (“AdBlue”) for SCR systems
Approved Nox reducing agent AUS 32 (“AdBlue”)
BB00.40-P-0352-01A
For diesel engines, the technology of direct injection of the diesel fuel has virtually become the standard. The very demanding exhaust emission legislation necessitates exhaust gas aftertreatment for diesel engines. 
For example, oxidation catalysts are common in numerous modern passenger car diesel engines today. Compliance with the latest exhaust emission regulations for passenger cars and light commercial vehicles will require the use of further exhaust aftertreatment systems, at least for the heavier vehicles in this category. Several different systems for reducing NOx emissions are being developed or are already in use. For heavy-duty commercial vehicles, a system for reducing nitrogen oxide emissions with higher selectivity, a NOx catalytic converter with urea as the reducing agent is used. This is a selective catalytic NOx reduction in the presence of ammonia.

Via an upstream reaction stage, the urea-water solution introduced into the exhaust tract is first converted into ammonia. In the following catalytic reduction, the NOx is largely decomposed to nitrogen and water in the presence of ammonia. The urea-SCR process for the exhaust gas aftertreatment is state of the art. A detailed quality description of the reducing agent is the basis for a nationwide, manufacturer-independent introduction of this technology and is necessary to ensure the long-term activity of the exhaust gas aftertreatment system. The requirements on the reducing agent are published in the form of the standards DIN 70070 (requirements) and DIN V 70071 (test methods).

Standardisation at the international level has been completed and can be found in the International Standard ISO 22241. For Mercedes-Benz-Trucks vehicles, an urea-water solution in accordance with the requirements of ISO 22241 must be used. In Europe, the brand name "AdBlue®" is often used for this quality. If qualities outside the specification are used, damage to the Bluetec system cannot be ruled out.
 `,
					PrologMotorOil: `Engine oil is an operating fluid important for the function and longevity of the engine. Therefore, only engine oils with “DTFR xxxxxx” are recommended for use in Mercedes-Benz-Trucks vehicles. DTFR xxxxxx of products can be found on the product packaging "DTFR xxxxxx" (e.g. DTFR 15B120). DTFR 15A100 list the particular engine oil specifications for all Mercedes-Benz-Trucks approved products.
Engine oil specifications approved for Mercedes-Benz-Trucks vehicles and engines can be found in the respective vehicle operator’s manual, and, if necessary, in the supplementary service information. 
The recommended SAE viscosity class according to application region can be found on DTFR 15A110.`,
					PrologEdac: `The following product list is intended to help you select the correct operating fluid for your Mercedes-Benz Trucks & Buses vehicle/unit from the wide variety available on the market.
					
						'We recommend to only use the products listed below which have been tested and approved by Daimler Truck.
						
						'The high-voltage air compressor is an oil-lubricated unit. This oil is used for lubrication, heat absorption and dissipation, as well as sealing of various internal functional surfaces and processes. Therefore, an oil change should be performed on the high-voltage air compressor as part of the service.`,
					CookieUse: `Daimler Truck AG uses cookies for various purposes`,
					PrologHydrogen: `Supplying fuel cell vehicles with a specified hydrogen quality is a prerequisite for a long service life of the fuel cell system.
 
Standardization at international level is therefore necessary and can be found in the international standard ISO 14687. For Mercedes-Benz Trucks vehicles, a hydrogen quality in accordance with the requirements of ISO 14687 must be used. If qualities outside the specification are used, damage to the fuel cell system cannot be ruled out.
The quality of the hydrogen at the dispenser for Class D hydrogen (ISO 14687 Chapter 5) must meet the requirements described therein. The fuel specifications are not process-dependent or raw material-specific.
The ISO 14687 standard (Appendix A) describes, among other things, the effects of various impurities (such as inert gases, carbon monoxide and dioxide, methane, ammonia, sulphur compounds, particles, etc.) on the fuel cell stacks/components.`,
					IntroductionContent: `This website is a platform for publishing Mercedes-Benz-Trucks tested and approved operating fluids for your vehicles and major assemblies. We recommend exclusively using products that have been tested and approved by Daimler Truck AG as:
· components and operating fluids are tailored to one another,
· damages that occur due to the usage of unsuited operating fluids are not covered by the warranty.
Approval by Mercedes-Benz-Trucks is clearly marked on the product packaging: e.g.: "DTFR 15C110" or "DTFR 15C110"
The website always shows the latest specifications for operating fluids and the relevant approvals for products on the market.

You can find the specifications for operating fluids relevant to your vehicle/ major assembly in the online Owner's Manual.`,
					Sheet: `DTFR-Sheet`,
					ProductApproval: 'Product is approved',
					ProductNotApproved: 'Product is not approved',
					Description: `Description`,
					Principal: `Principal`,
					Interested: `You might also be interested in:`,
					TopCategories: `Our top categories`,
					BrakeFluid: `Brake Fluid`,
					Info: `Informations to Specification for Operating Fluids`,
					EngineOil: `Engine Oil`,
					Fuel: `Fuel`,
					GearOil: `Gear Oil`,
					Grease: `Grease`,
					HydraulicFluid: `Hydraulic Fluid`,
					PreservationAgent: `Preservation Agent`,
					RefrigeratorOil: `Refrigerator Oil`,
					SteeringGearOil: `Steering Gear Oil`,
					AdBlue: `NOx Reducing Agent`,
					Coolant: `Coolant`,
					Refrigerant: `Refrigerant`,
					Windshield: `Windshield Washer Fluid Concentrate`,
					ReservedRights: `© ${new Date().getFullYear()}. Daimler Truck AG. All rights reserved`,
					PrivacyStatement: `Privacy Statement`,
					Cookies: `Cookies`,
					Category: `Category`,
					LegalNotice: `Legal Notice`,
					Settings: `Settings`,
					Categories: `Categories`,
					Getriebeöl: `Gear Oil`,
					Motoröl: `Motor Oil`,
					Bremsflüssigkeit: `Brake Fluid`,
					Kraftstoff: `Fuel`,
					Fett: `Grease`,
					Hydrauliköl: `Hydraulic Fluid`,
					Konservierungsmittel: `Preservation Agent`,
					Kompressoröl: `Refrigerator Oil`,
					Lenkgetriebeöl: `Stearing Gear Oil`,
					Frostschutz: `Coolant`,
					Kältemittel: `Refrigerant`,
					"E-Drive": `E-Drive`,
					"E-Engine-Oil": `E-Motor-Oil`,
					"E-Motor-Oil": `E-Motor-Oil`,
					"edAC-Oil": "edAC-Oil",
					"Axle-Oil": "Axle Oil",
					OilFinder: `Oil finder`,
					Provider: `Provider`,
					MappingOverview: `DTFR Overview`,
					Contact: `Contact`,
					LearnMore: `Learn more`,
					MbOgOil: `Mercedes-Benz original motor oil`,
					MbChoice: `If your Mercedes has the choice:`,
					OperatingInstructions: `Online Owner's Manual`,
					OperatingInstructionsContent: `You can find the Specifications for Operating Fluids relevant to your vehicle/ major assembly in the online Owner's Manual.`,
					ContactUrl: `https://www.mbcac.net/go/appsupport/Contact.aspx?app=Mercedes-Benz%20BeVo&lang=en-EN`,
					Type30: '30',
					Type40: '40',
					Type50: '50',
					Type80: '80',
					Type90: '90',
					Type0W: '0W',
					Type0W20: '0W-20',
					Type0W30: '0W-30',
					Type0W40: '0W-40',
					Type5W30: '5W-30',
					Type5W40: '5W-40',
					Type5W50: '5W-50',
					Type10W: '10W',
					Type10W30: '10W-30',
					Type10W40: '10W-40',
					Type20W20: '20W-20',
					Type20W50: '20W-50',
					Type75W: '75W',
					Type75W80: '75W-80',
					Type75W85: '75W-85',
					Type75W90: '75W-90',
					Type80W: '80W',
					Type80W90: '80W-90',
					Type80W85: '80W-85',
					Type85W90: '85W-90',
				},
			},
			de: {
				translations: {
					mainPage: {
						title: "Mercedes-Benz Trucks Betriebsstoff-Vorschriften",
						description: "Entdecke alle geprüften und freigegebenen Betriebsstoffe für Actros, Atego, Arocs und viele weitere Modelle von Mercedes-Benz Trucks | Ein Service der Daimler Truck AG.",
						keywords: "Betriebsstoffe, Motoröl, Getriebeöl,  Daimler Truck AG, DTFR-Nummer, Kühlmittel, eAntriebssysteme, Instandhaltung, Getriebeöle, freigegebenen Motoröle, LKW und Bus-Bereich, LKW, Bus, Actros, Atego, Arocs, Bereich",
						author: " Daimler Truck AG",
						robots: "index, follow"
						},
					categoryOverview: {
							title: "Betriebsflüssigkeit Kategorien",
							description: "Neben klassischen Kategorien wie Motoröl, Getriebeöl und Kühlmittel finden sie auch weitere freigegebene Produkte z.B. für eAntriebssysteme oder Instandhaltung Motoröl.",
						},
					MbApprovalLabel: `MB-Freigabe/MB-Approval`,
					Here: `hier`,
					MailFeedbackPartOne: `Ihre Nachricht wurde erfolgreich versendet.

          Sie werden auf die Startseite weitergeleitet, in `,
					MailFeedbackPartTwo: ` Sekunden. 
          Werden Sie nicht automatisch weitergeleitet, klicken Sie bitte `,
					CookieBarHeader:
						'Die Mercedes-Benz AG nutzt Cookies für verschiedene Zwecke',
					CookieBarContent:
						'Damit möchten wir Ihnen die bestmögliche Nutzung unserer Webseite ermöglichen, sowie unsere Webseite fortlaufend verbessern. Sie können Ihre freiwillige Zustimmung jederzeit widerrufen. Weitere Informationen und Einstellungsmöglichkeiten finden Sie unter „Einstellungen“.',
					CookieBarDetails: 'Details einblenden',
					TechnicallyNeccessary: 'Technisch erforderlich',
					Analytics: 'Analyse und Statistik',
					SaveSelected: 'Einstellungen speichern',
					AgreeToAll: 'Allem zustimmen',
					WelcomeToSupport: 'Kontakt',
					SheetNumber: `Blattnummer`,
					ProductAllowerdText: `Produkt ist freigegeben`,
					SupportInfo:
						'Haben Sie eine Frage oder einen Verbesserungsvorschlag bezüglich einer unserer Apps oder Webseiten? Unsere Kundenbetreuung steht Ihnen zur Verfügung. Bitte teilen Sie uns Ihr Anliegen mit, wir kümmern uns dann um die zügige Weiterleitung an den zuständigen Anbieter der Daimler AG. Als Tochterunternehmen der Daimler AG kümmert sich das Customer Assistance Center in Maastricht um die professionelle Bearbeitung aller Anfragen.',
					SupportAsterisk:
						'Um Ihnen schnell und effektiv behilflich sein zu können, bitten wir Sie alle mit * gekennzeichnete Felder auf jeden Fall auszufüllen.',
					FirstName: 'Vorname*',
					LastName: 'Nachname*',
					StreetName: 'Straße',
					HouseNumber: 'Nr',
					PostalCode: 'PLZ',
					City: 'Ort',
					Country: 'Land',
					Email: 'E-Mail',
					Message: 'Mitteilung',
					SupportPolicy:
						'Ich bin damit einverstanden, dass die im Kontaktformular angegebenen Daten zum Zweck der Beantwortung meiner Anfrage an den in der App oder an den auf der Website genannten Anbieter und soweit erforderlich auch an den jeweiligen technischen Entwickler übermittelt werden. ',
					Send: 'Versenden',
					Title: 'Betreff',
					ErrorFirstName: 'Bitte Vornamen eingeben',
					ErrorLastName: 'Bitte Nachnamen eingeben',
					ErrorEmail: 'Bitte geben Sie hier Ihre E-Mailadresse an',
					ErrorMessage: 'Bitte Feld ausfüllen',
					ErrorTitle: 'Bitte Betreff eingeben',
					ErrorCountry: 'Please enter a country',
					ErrorPostalCode: 'Please enter a postal code',
					LastUpdate: 'Stand: ',
					ChoooseBeVo: 'Bitte wählen Sie nachfolgend einen Betriebsstoff aus:',
					Link1:
						'https://www.mercedes-benz.com/de/fahrzeuge/service-teile/das-einzige-oel-mit-mercedes-benz-know-how/',
					Link2:
						'https://www.mercedes-benz.de/passengercars/services-accessories/manuals.html',
					SearchResultPart1: 'Ihre Suche ergab',
					SearchResultPart2: 'Ergebnisse',
					BackToOverview: '← Zurück zur Übersicht',
					SearchLabel: 'Ihr Suchbegriff',
					PrologGearOil: `Das Getriebeöl ist ein für die Funktion und Langlebigkeit des Getriebes wichtiger Betriebsstoff. Die Mercedes-Benz-Trucks Getriebeöle werden eigens für den Einsatz in Mercedes-Benz-Trucks Fahrzeugen entwickelt und erprobt. Daher empfehlen wir für die die Verwendung in Mercedes-Benz-Trucks Fahrzeugen ausschließlich nur Öle mit „DTFR xxxxxx“. Die „DTFR xxxxxx“ von Produkten ist über die Angabe (z.B. DTFR 13A100) auf dem Gebinde erkenntlich. Auf den DTFR-Blättern 13xxxx und 12xxxx sind alle für die jeweilige Getriebeölspezifikation der von der Daimler Truck AG freigegebenen Produkte gelistet.
Die für Mercedes-Benz-Trucks Fahrzeuge freigegebenen Achs- und Getriebeölspezifikationen können der jeweiligen Fahrzeug-Betriebsanleitung und ggf. ergänzenden Service-Informationen entnommen werden.`,
					ProductName: 'Produktname',
					ShowMore: 'Mehr anzeigen',
					ShowLess: 'Weniger anzeigen',
					NoProduct: 'Leider keine Produkte verfügbar',
					PrologFuel: `Energiequellen für die Verbrennungsmotoren in Kraftfahrzeugen sind Kraftstoffe, die durch hoch entwickelte chemisch-physikalische Verfahren aus Erdöl oder erneuerbaren Quellen hergestellt werden. Diese Kraftstoffe sind komplexe Gemische unterschiedlich aufgebauter Verbindungen bestehend aus Kohlenstoff, Wasserstoff und eventuell Sauerstoff und auf die Verbrennungsverfahren der Motoren abgestimmt. Bezogen auf gleiches Gewicht besitzen sie im Vergleich zu anderen brennbaren Flüssigkeiten eine große Energiedichte. Die gespeicherte Energie wird während der Verbrennung im Motor in mechanische Energie umgewandelt.
Weltweit gesehen gibt es eine große Qualitätsspanne. Die Qualität des Kraftstoffs spielt für die Performance und die Abgasemissionen der Fahrzeuge eine entscheidende Rolle. Konsequenterweise sollte die Kraftstoffqualität entsprechend der Fahrzeuganforderungen auf einem angemessenen Niveau harmonisiert werden. Um diese Ziele zu erreichen, wurde seitens der weltweiten Automobilindustrie die "World Wide Fuel Charter" ("WWFC") geschaffen, die geeignete Zielvorstellungen von Kraftstoffeigenschaften für die verschiedenen Abgas- und CO2-Anforderungen definiert. Die WWFC wird in regelmäßigen Abständen überarbeitet und dem neuesten Stand der Technik angepasst. Die Marktqualität der Kraftstoffe wird üblicherweise durch nationale Normen festgelegt.

<strong>Ottokraftstoff - Benzin</strong>
Für gewöhnlich werden mit dem Begriff „Benzin“ die leichteren Rohölfraktionen beschrieben, die während des Raffinerieprozesses von den schweren Fraktionen getrennt werden. Chemisch betrachtet besteht die Flüssigkeit aus organischen Molekülen die innerhalb eines bestimmten Siedebereichs (~40-200 °C) liegen. Diese Moleküle bestehen vornehmlich aus Kohlenstoff- und Wasserstoffatomen. Im Rohöl ist auch Schwefel enthalten, welcher reduziert oder entfernt werden muss, um Benzin herzustellen, das weder Motor noch andere Fahrzeugtechnologien schädigen kann. Die Zündung von Ottokraftstoffen erfolgt durch eine externe Zündquelle (z. B. Zündkerze).
Die Hauptklassifizierung von Benzin erfolgt anhand der Klopffestigkeit, welche durch die Research- und Motor-Oktanzahl beschrieben wird. Typische Angaben sind zum Beispiel: ROZ 95 und ROZ 98. Im europäischen Markt aber auch in anderen Märkten ist neben der Klopffestigkeit auch der Ethanolgehalt aufgrund der Fahrzeugkompatibilität ein wichtiger Parameter. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Dieselkraftstoff</strong>
Dieselkraftstoff wird für gewöhnlich als die Mitteldestillat-Fraktion des Rohöls bezeichnet, die während des Raffinerieprozesses von den leichteren und schwereren Fraktionen getrennt werden. Chemisch betrachtet besteht die Flüssigkeit aus organischen Molekülen die innerhalb eines bestimmten Siedebereichs (~160-350 °C) liegen. Diese Moleküle bestehen vornehmlich aus Kohlenstoff- und Wasserstoffatomen. Für spezifische Kälteeigenschaften benötigen bestimmte geradkettige Moleküle eine Modifikation. Im Rohöl ist auch Schwefel enthalten, welcher reduziert oder entfernt werden muss, um Diesel herzustellen, welcher weder Motor noch andere Fahrzeugtechnologien schädigen kann. In Europa darf der Dieselkraftstoff bis zu 7 Vol. % Biodiesel enthalten. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Paraffinische Dieselkraftstoffe</strong>
Paraffinische Kraftstoffe bestehen nahezu vollständig aus Alkanen und sind folglich frei von Aromaten und Schwefel. Neben linearen und verzweigten Alkanen sind teilweise auch Cycloalkane enthalten. Bedingt durch die chemische Zusammensetzung unterscheiden sich die Eigenschaften paraffinischer Kraftstoffe von denen des regulären Dieselkraftstoffs. Die Hauptunterschiede paraffinischer Dieselkraftstoffe zu konventionellen Dieselkraftstoffen stellen die höhere Cetanzahl und die niedrigere Dichte dar. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Kraftstoffadditive und Sekundärzusätze</strong>
Durch die hohen Anforderungen, die an Kraftstoffe gestellt werden, ist es notwendig bestimmte Eigenschaften der Grundkraftstoffe zu optimieren. Additive können den Kraftstoffen zusätzliche Eigenschaften verleihen oder vorhandene Eigenschaften hervorheben oder abschwächen. Bei Additiven handelt es sich um kraftstofflösliche Verbindungen überwiegend organisch-chemischer Natur.
Die richtige Auswahl, Anwendung und Dosierung solcher Additive setzen eingehende Untersuchungen im Labor, auf Prüfständen und in Fahrzeugen voraus, damit die Wirkung der Additive dem jeweiligen Kraftstoff optimal angepasst ist, die Zusätze aufeinander abgestimmt sind und keine negativen Nebeneffekte bewirkt werden. Da dem Verbraucher in der Regel die erforderlichen Voraussetzungen hierzu fehlen, kann die Kraftstoffadditivierung ausschließlich Aufgabe der Kraftstoffhersteller sein.

Dem Fahrer werden jedoch immer wieder Kraftstoff-Additive angeboten, die teilweise große Erfolge, wie z. B. höhere Motorleistung bei niedrigerem Kraftstoffverbrauch, versprechen. Diese Zusätze werden von uns, der besseren Unterscheidung wegen, als Sekundärzusätze bezeichnet. Bei unseren Fahrzeugmotoren sind Sekundärzusätze im Allgemeinen nicht erforderlich, da in den meisten Fällen mit gleichmäßigen und ausreichenden Kraftstoffqualitäten gerechnet werden kann. Es ist darauf zu achten, dass nur die jeweils von uns vorgeschriebene Kraftstoffqualität verwendet wird. Der Einsatz von Sekundärzusätzen ist dagegen im Allgemeinen nur eine zusätzliche, nicht erforderliche Kostenbelastung und kann im schlimmsten Fall zu nachhaltigen Schäden führen. In einzelnen Märkten mit schlechter Kraftstoffqualität kann eine zusätzliche Additivierung erforderlich sein. In diesem Sonderfall werden für Mercedes-Benz-Trucks Fahrzeuge erprobte und freigegebene Additive von den Mercedes-Benz-
Trucks Fachwerkstätten empfohlen. Die auf dem Behälter angegebenen Hinweise und Mischungsverhältnisse sind zwingend zu beachten.
Von der Anwendung von Sekundärzusätzen ohne DTFR Freigabe ist dringend abzuraten. Sie erfolgt immer in eigener Verantwortung des Fahrzeugbetreibers, da deren Verwendung die Gewährleistung sowohl des Fahrzeugherstellers- als auch des Kraftstofflieferanten beeinträchtigen kann.`,
					CookieUse: `Die Daimler Truck AG nutzt Cookies für verschiedene Zwecke`,
					PrologAdBlue: `NOx-Reduktionsmittel AUS 32 ("AdBlue") für SCR-Anlagen
Freigegebene NOx-Reduktionsmittel AUS 32("AdBlue")
BB00.40-P-0352-01A
Bei Dieselmotoren hat sich die Technik der Direkteinspritzung des Dieselkraftstoffs nahezu zum Standard entwickelt. Die sehr anspruchsvolle Abgasgesetzgebung macht bei Dieselmotoren eine Abgasnachbehandlung erforderlich. So sind bei zahlreichen modernen PKW-Dieselmotoren heute Oxidationskatalysatoren üblich. Die Einhaltung der neuesten Abgasvorschriften für Personenkraftwagen und leichte Nutzfahrzeuge wird zumindest für die schwereren Fahrzeuge dieser Kategorie den Einsatz weiterer Abgasnachbehandlungssysteme erforderlich machen. Zur Verringerung der NOx-Emission ist eine Reihe von unterschiedlichen Systemen in der Entwicklung bzw. bereits im Einsatz. Bei schweren Nutzfahrzeugen wird als ein System zur Verringerung der Stickoxidemission mit höherer Selektivität der Nox-Katalysator mit Harnstoff als Reduktionsmittel eingesetzt. Hierbei handelt es sich um eine selektive katalytische Nox-Reduktion in Gegenwart von Ammoniak.
Über eine vorgelagerte Reaktionsstufe wird die in den Abgastrakt eingebrachte Harnstoff-Wasserlösung zunächst in Ammoniak überführt. Bei der folgenden katalytischen Reduktion wird das Nox in Gegenwart von Ammoniak weitgehend zu Stickstoff und Wasser zersetzt. Das Harnstoff-SCR-Verfahren für die Abgasnachbehandlung ist Stand der Technik. Eine detaillierte Qualitätsbeschreibung des Reduktionsmittels ist Grundlage für eine flächendeckende, herstellerunabhängige Einführung dieser Technik und erforderlich zur Sicherung der Daueraktivität der Abgasnachbehandlungsanlage. Die Anforderungen für das Reduktionsmittel ist in Gestalt der Normen DIN 70070 (Anforderungen) und DIN V 70071 (Prüfverfahren) veröffentlicht.
Eine Normung auf internationaler Ebene ist abgeschlossen und findet sich in dem Internationalen Standard ISO 22241. Für Mercedes-Benz-Trucks Fahrzeuge ist eine Harnstoffwasserlösung nach den Anforderungen der ISO 22241 einzusetzen. In Europa wird der Markenname "AdBlue®" für diese Qualität häufig verwendet. Bei Verwendung von Qualitäten außerhalb der Spezifikation können Schäden am Bluetec System nicht ausgeschlossen werden.`,
					PrologCoolant: `Korrosions-Frostschutzmittel: 
- gewährleisten Werkstoffverträglichkeit 
- ermöglichen optimale Wärmeabfuhr 
- schützen vor Korrosion 
Die freigegebenen Korrosions-/Frostschutzmittel bzw. Kühlmittel erfüllen die anspruchsvollen Mercedes-Benz-Trucks Spezifikationen. Ein hervorragender Betrieb der Kühlsysteme unter den fahrzeugspezifischen Belastungen ist dadurch gewährleistet. 
Entscheidend ist nicht nur die Wahl des Kühlmittels, sondern auch die Einhaltung der optimalen Wasserqualität und Mischungskonzentration zwischen Konzentrat- und Wasseranteilen während des Betriebs: zu viel Additivpaket hilft so wenig wie zu viel Wasser. 
Im DTFR Blatt 29A100 befinden sich allgemeine Informationen für den korrekten Umgang der Kühlmittel. 
Im DTFR Blatt 29A110 sind alle für die jeweiligen Anwendungen freigegebenen Produkten gelistet.
`,
					PrologMotorOil: `Das Motoröl ist ein für die Funktion und Langlebigkeit des Motors wichtiger Betriebsstoff. Daher wird für die Verwendung in Mercedes-Benz-Trucks Fahrzeugen ausschließlich Motoröle mit DTFR xxxxxx empfohlen. Die DTFR xxxxxx von Produkten ist über die Angabe auf dem Gebinde „DTFR xxxxxx“ (z.B. DTFR 15B120) erkenntlich. In der DTFR 15A100 sind alle für die jeweilige Motorölspezifikation der von der Daimler Truck AG freigegebenen Produkte gelistet.
Die für Mercedes-Benz-Trucks Fahrzeuge und -motoren freigegebenen Motorölspezifikationen können der jeweiligen Fahrzeug-Betriebsanleitung und ggf. ergänzenden Service-Informationen entnommen werden. 
Die abhängig von der Anwendungsregion empfohlene SAE Viskositätsklasse kann dem DTFR 15A110 entnommen werden.`,
					PrologEdac: `Die nachfolgende Produktliste soll Ihnen helfen, den richtigen Betriebsstoff für Ihr Mercedes-Benz Trucks & Buses Fahrzeug/ Aggregat aus der Marktvielfalt auszuwählen.
					
Wir empfehlen, ausschließlich die in der nachfolgenden Liste aufgeführten, von Daimler Truck geprüften und freigegebenen, Produkte zu verwenden.

Der Hochvolt-Luftpresser ist ein öl-geschmiertes Aggregat. Dieses Öl dient zu Schmierung, Wärmeaufnahme und Abführung, sowie Dichtung verschiedenster, interner Funktionsflächen und Prozesse. Daher sollte im Rahmen des Service ein Ölwechsel am Hochvolt-Luftpresser vorgenommen werden.
					`,
					PrologHydrogen: `Eine Versorgung der Brennstoffzellenfahrzeuge mit einer vorgegebenen Wasserstoffqualität ist Voraussetzung für eine hohe Lebensdauer des Brennstoffzellensystems.

Eine Normung auf internationaler Ebene ist somit erforderlich und findet sich in dem Internationalen Standard ISO 14687. Für Mercedes-Benz-Trucks Fahrzeuge ist eine Wasserstoffqualität nach den Anforderungen der ISO 14687 einzusetzen. Bei Verwendung von Qualitäten außerhalb der Spezifikation können Schäden am Brennstoffzellensystem nicht ausgeschlossen werden.
Die Qualität des Wasserstoffs an der Zapfsäule für Wasserstoff der KlasseD (ISO 14687 Kapitel 5) muss den darin beschriebenen Anforderungen entsprechen. Die Kraftstoffspezifikationen sind nicht prozessabhängig oder rohstoffspezifisch.
In der Norm ISO 14687 (Anhang A) werden u.a. die Auswirkungen von verschiedensten Verunreinigungen (wie z.B. Inertgase, Kohlenmonoxid und -dioxid, Methan, Ammoniak, Schwefelverbindungen, Partikel etc.) auf die Brennstoffzellenstacks/-komponenten beschrieben.
					`,
					IntroductionContent: `Diese Seite ist eine Veröffentlichungsplattform für die von Mercedes-Benz-Trucks geprüften und freigegebenen Betriebsstoffe für Ihre Fahrzeuge und Aggregate. Generell empfehlen wir ausschließlich Produkte zu verwenden, die von Mercedes-Benz-Trucks geprüft und freigegeben wurden, da: 
- Konstruktionsteile und Betriebsstoffe aufeinander abgestimmt sind, 
- Schäden, die durch die Verwendung ungeeigneter Betriebsstoffe entstehen, nicht unter die Gewährleistung fallen. 
Von Mercedes-Benz-Trucks freigegebene Produkte sind auf den Gebinden eindeutig gekennzeichnet: z.B. „DTFR 15C110 “ 
Die Webseite bildet immer den aktuellen Stand der Betriebsstoff-Vorschriften und der entsprechenden Produktfreigaben im Markt ab.
`,
					Sheet: `DTFR-Blatt`,
					Description: `Beschreibung`,
					Principal: `Auftraggeber`,
					Interested: `Das könnte Sie auch interessieren:`,
					TopCategories: `Unsere Top-Kategorien`,
					BrakeFluid: `Bremsflüssigkeit`,
					Info: `Informationen zu Betriebsstoff-Vorschriften`,
					EngineOil: `Motoröl`,
					Fuel: `Kraftstoff`,
					Getriebeöl: `Getriebeöl`,
					Motoröl: `Motoröl`,
					Bremsflüssigkeit: `Bremsflüssigkeit`,
					Kraftstoff: `Kraftstoff`,
					Fett: `Fett`,
					Hydrauliköl: `Hydrauliköl`,
					Konservierungsmittel: `Konservierungsmittel`,
					Kompressoröl: `Kompressoröl`,
					Lenkgetriebeöl: `Lenkgetriebeöl`,
					Frostschutz: `Frostschutz`,
					Kältemittel: `Kältemittel`,
					GearOil: `Getriebeöl`,
					Grease: `Fett`,
					"E-Drive": `E-Antriebssysteme`,
					"E-Engine-Oil": `E-Motoröl`,
					"E-Motor-Oil": `E-Motoröl`,
					"edAC-Oil": "edAC-Öl",
					"Axle-Oil": "Achsenöl",
					"Axle Oil": "Achsenöl",
					HydraulicFluid: `Hydrauliköl`,
					PreservationAgent: `Konservierungsmittel`,
					RefrigeratorOil: `Kompressoröl`,
					SteeringGearOil: `Lenkgetriebeöl`,
					AdBlue: `NOx-Reduktionsmittel`,
					Coolant: `Korrosions- / Frostschutzmittel`,
					Refrigerant: `Kältemittel`,
					Windshield: `Scheibenwaschmittel-Konzentrat`,
					ReservedRights: `© ${new Date().getFullYear()}. Daimler Truck AG. Alle Rechte vorbehalten`,
					PrivacyStatement: `Datenschutz`,
					Cookies: `Cookies`,
					LegalNotice: `Rechtliche Hinweise`,
					Settings: `Einstellungen`,
					Categories: `Kategorien`,
					OilFinder: `Öl-Finder`,
					Provider: `Anbieter`,
					MappingOverview: `DTFR Übersicht`,
					Contact: `Kontakt`,
					LearnMore: `Mehr erfahren`,
					MbOgOil: `Mercedes-Benz Original-Motoröl`,
					MbChoice: `Wenn ihr Mercedes die Wahl hätte:`,
					OperatingInstructions: `Digitale Betriebsanleitungen`,
					OperatingInstructionsContent: `In den Online-Bedienungsanleitung finden Sie die zu Ihrem Fahr-zeug/Aggregat passenden Betriebsstoff-Spezifikationen`,
					ContactUrl: `https://www.mbcac.net/go/appsupport/Contact.aspx?app=Mercedes-Benz%20BeVo&lang=de-DE`,
					Type30: '30',
					Type40: '40',
					Type50: '50',
					Type80: '80',
					Type90: '90',
					Type0W: '0W',
					Type0W20: '0W-20',
					Type0W30: '0W-30',
					Type0W40: '0W-40',
					Type5W30: '5W-30',
					Type5W40: '5W-40',
					Type5W50: '5W-50',
					Type10W: '10W',
					Type10W30: '10W-30',
					Type10W40: '10W-40',
					Type20W20: '20W-20',
					Type20W50: '20W-50',
					Type75W: '75W',
					Type75W80: '75W-80',
					Type75W85: '75W-85',
					Type75W90: '75W-90',
					Type80W: '80W',
					Type80W90: '80W-90',
					Type80W85: '80W-85',
					Type85W90: '85W-90',
				},
			},
			pt: {
				translations: {
					MbApprovalLabel: `MB-Freigabe/MB-Approval`,
					Here: `hier`,
					MailFeedbackPartOne: `Ihre Nachricht wurde erfolgreich versendet.

          Sie werden auf die Startseite weitergeleitet, in `,
					MailFeedbackPartTwo: ` Sekunden. 
          Werden Sie nicht automatisch weitergeleitet, klicken Sie bitte `,
					CookieBarHeader:
						'Die Mercedes-Benz AG nutzt Cookies für verschiedene Zwecke',
					CookieBarContent:
						'Damit möchten wir Ihnen die bestmögliche Nutzung unserer Webseite ermöglichen, sowie unsere Webseite fortlaufend verbessern. Sie können Ihre freiwillige Zustimmung jederzeit widerrufen. Weitere Informationen und Einstellungsmöglichkeiten finden Sie unter „Einstellungen“.',
					CookieBarDetails: 'Details einblenden',
					TechnicallyNeccessary: 'Technisch erforderlich',
					Analytics: 'Analyse und Statistik',
					SaveSelected: 'Einstellungen speichern',
					AgreeToAll: 'Allem zustimmen',
					WelcomeToSupport: 'Kontakt',
					SheetNumber: `Blattnummer`,
					ProductAllowerdText: `Produkt ist freigegeben`,
					SupportInfo:
						'Haben Sie eine Frage oder einen Verbesserungsvorschlag bezüglich einer unserer Apps oder Webseiten? Unsere Kundenbetreuung steht Ihnen zur Verfügung. Bitte teilen Sie uns Ihr Anliegen mit, wir kümmern uns dann um die zügige Weiterleitung an den zuständigen Anbieter der Daimler AG. Als Tochterunternehmen der Daimler AG kümmert sich das Customer Assistance Center in Maastricht um die professionelle Bearbeitung aller Anfragen.',
					SupportAsterisk:
						'Um Ihnen schnell und effektiv behilflich sein zu können, bitten wir Sie alle mit * gekennzeichnete Felder auf jeden Fall auszufüllen.',
					FirstName: 'Vorname*',
					LastName: 'Nachname*',
					StreetName: 'Straße',
					HouseNumber: 'Nr',
					PostalCode: 'PLZ',
					City: 'Ort',
					Country: 'Land',
					Email: 'E-Mail',
					Message: 'Mitteilung',
					SupportPolicy:
						'Ich bin damit einverstanden, dass die im Kontaktformular angegebenen Daten zum Zweck der Beantwortung meiner Anfrage an den in der App oder an den auf der Website genannten Anbieter und soweit erforderlich auch an den jeweiligen technischen Entwickler übermittelt werden. ',
					Send: 'Versenden',
					Title: 'Betreff',
					ErrorFirstName: 'Bitte Vornamen eingeben',
					ErrorLastName: 'Bitte Nachnamen eingeben',
					ErrorEmail: 'Bitte geben Sie hier Ihre E-Mailadresse an',
					ErrorMessage: 'Bitte Feld ausfüllen',
					ErrorTitle: 'Bitte Betreff eingeben',
					ErrorCountry: 'Please enter a country',
					ErrorPostalCode: 'Please enter a postal code',
					LastUpdate: 'Stand: ',
					ChoooseBeVo: 'Bitte wählen Sie nachfolgend einen Betriebsstoff aus:',
					Link1:
						'https://www.mercedes-benz.com/de/fahrzeuge/service-teile/das-einzige-oel-mit-mercedes-benz-know-how/',
					Link2:
						'https://www.mercedes-benz.de/passengercars/services-accessories/manuals.html',
					SearchResultPart1: 'Ihre Suche ergab',
					SearchResultPart2: 'Ergebnisse',
					BackToOverview: '← Zurück zur Übersicht',
					SearchLabel: 'Ihr Suchbegriff',
					PrologGearOil: `Das Getriebeöl ist ein für die Funktion und Langlebigkeit des Getriebes wichtiger Betriebsstoff. Die Mercedes-Benz-Trucks Getriebeöle werden eigens für den Einsatz in Mercedes-Benz-Trucks Fahrzeugen entwickelt und erprobt. Daher empfehlen wir für die die Verwendung in Mercedes-Benz-Trucks Fahrzeugen ausschließlich nur Öle mit „DTFR xxxxxx“. Die „DTFR xxxxxx“ von Produkten ist über die Angabe (z.B. DTFR 13A100) auf dem Gebinde erkenntlich. Auf den DTFR-Blättern 13xxxx und 12xxxx sind alle für die jeweilige Getriebeölspezifikation der von der Daimler Truck AG freigegebenen Produkte gelistet.
Die für Mercedes-Benz-Trucks Fahrzeuge freigegebenen Achs- und Getriebeölspezifikationen können der jeweiligen Fahrzeug-Betriebsanleitung und ggf. ergänzenden Service-Informationen entnommen werden.`,
					ProductName: 'Produktname',
					ShowMore: 'Mehr anzeigen',
					ShowLess: 'Weniger anzeigen',
					NoProduct: 'Leider keine Produkte verfügbar',
					PrologFuel: `Energiequellen für die Verbrennungsmotoren in Kraftfahrzeugen sind Kraftstoffe, die durch hoch entwickelte chemisch-physikalische Verfahren aus Erdöl oder erneuerbaren Quellen hergestellt werden. Diese Kraftstoffe sind komplexe Gemische unterschiedlich aufgebauter Verbindungen bestehend aus Kohlenstoff, Wasserstoff und eventuell Sauerstoff und auf die Verbrennungsverfahren der Motoren abgestimmt. Bezogen auf gleiches Gewicht besitzen sie im Vergleich zu anderen brennbaren Flüssigkeiten eine große Energiedichte. Die gespeicherte Energie wird während der Verbrennung im Motor in mechanische Energie umgewandelt.
Weltweit gesehen gibt es eine große Qualitätsspanne. Die Qualität des Kraftstoffs spielt für die Performance und die Abgasemissionen der Fahrzeuge eine entscheidende Rolle. Konsequenterweise sollte die Kraftstoffqualität entsprechend der Fahrzeuganforderungen auf einem angemessenen Niveau harmonisiert werden. Um diese Ziele zu erreichen, wurde seitens der weltweiten Automobilindustrie die "World Wide Fuel Charter" ("WWFC") geschaffen, die geeignete Zielvorstellungen von Kraftstoffeigenschaften für die verschiedenen Abgas- und CO2-Anforderungen definiert. Die WWFC wird in regelmäßigen Abständen überarbeitet und dem neuesten Stand der Technik angepasst. Die Marktqualität der Kraftstoffe wird üblicherweise durch nationale Normen festgelegt.

<strong>Ottokraftstoff - Benzin</strong>
Für gewöhnlich werden mit dem Begriff „Benzin“ die leichteren Rohölfraktionen beschrieben, die während des Raffinerieprozesses von den schweren Fraktionen getrennt werden. Chemisch betrachtet besteht die Flüssigkeit aus organischen Molekülen die innerhalb eines bestimmten Siedebereichs (~40-200 °C) liegen. Diese Moleküle bestehen vornehmlich aus Kohlenstoff- und Wasserstoffatomen. Im Rohöl ist auch Schwefel enthalten, welcher reduziert oder entfernt werden muss, um Benzin herzustellen, das weder Motor noch andere Fahrzeugtechnologien schädigen kann. Die Zündung von Ottokraftstoffen erfolgt durch eine externe Zündquelle (z. B. Zündkerze).
Die Hauptklassifizierung von Benzin erfolgt anhand der Klopffestigkeit, welche durch die Research- und Motor-Oktanzahl beschrieben wird. Typische Angaben sind zum Beispiel: ROZ 95 und ROZ 98. Im europäischen Markt aber auch in anderen Märkten ist neben der Klopffestigkeit auch der Ethanolgehalt aufgrund der Fahrzeugkompatibilität ein wichtiger Parameter. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Dieselkraftstoff</strong>
Dieselkraftstoff wird für gewöhnlich als die Mitteldestillat-Fraktion des Rohöls bezeichnet, die während des Raffinerieprozesses von den leichteren und schwereren Fraktionen getrennt werden. Chemisch betrachtet besteht die Flüssigkeit aus organischen Molekülen die innerhalb eines bestimmten Siedebereichs (~160-350 °C) liegen. Diese Moleküle bestehen vornehmlich aus Kohlenstoff- und Wasserstoffatomen. Für spezifische Kälteeigenschaften benötigen bestimmte geradkettige Moleküle eine Modifikation. Im Rohöl ist auch Schwefel enthalten, welcher reduziert oder entfernt werden muss, um Diesel herzustellen, welcher weder Motor noch andere Fahrzeugtechnologien schädigen kann. In Europa darf der Dieselkraftstoff bis zu 7 Vol. % Biodiesel enthalten. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Paraffinische Dieselkraftstoffe</strong>
Paraffinische Kraftstoffe bestehen nahezu vollständig aus Alkanen und sind folglich frei von Aromaten und Schwefel. Neben linearen und verzweigten Alkanen sind teilweise auch Cycloalkane enthalten. Bedingt durch die chemische Zusammensetzung unterscheiden sich die Eigenschaften paraffinischer Kraftstoffe von denen des regulären Dieselkraftstoffs. Die Hauptunterschiede paraffinischer Dieselkraftstoffe zu konventionellen Dieselkraftstoffen stellen die höhere Cetanzahl und die niedrigere Dichte dar. Details zu den geeigneten Kraftstoffen für ihr Fahrzeug finden Sie in der Betriebsanleitung und auf der Innenseite der Tankklappe.

<strong>Kraftstoffadditive und Sekundärzusätze</strong>
Durch die hohen Anforderungen, die an Kraftstoffe gestellt werden, ist es notwendig bestimmte Eigenschaften der Grundkraftstoffe zu optimieren. Additive können den Kraftstoffen zusätzliche Eigenschaften verleihen oder vorhandene Eigenschaften hervorheben oder abschwächen. Bei Additiven handelt es sich um kraftstofflösliche Verbindungen überwiegend organisch-chemischer Natur.
Die richtige Auswahl, Anwendung und Dosierung solcher Additive setzen eingehende Untersuchungen im Labor, auf Prüfständen und in Fahrzeugen voraus, damit die Wirkung der Additive dem jeweiligen Kraftstoff optimal angepasst ist, die Zusätze aufeinander abgestimmt sind und keine negativen Nebeneffekte bewirkt werden. Da dem Verbraucher in der Regel die erforderlichen Voraussetzungen hierzu fehlen, kann die Kraftstoffadditivierung ausschließlich Aufgabe der Kraftstoffhersteller sein.

Dem Fahrer werden jedoch immer wieder Kraftstoff-Additive angeboten, die teilweise große Erfolge, wie z. B. höhere Motorleistung bei niedrigerem Kraftstoffverbrauch, versprechen. Diese Zusätze werden von uns, der besseren Unterscheidung wegen, als Sekundärzusätze bezeichnet. Bei unseren Fahrzeugmotoren sind Sekundärzusätze im Allgemeinen nicht erforderlich, da in den meisten Fällen mit gleichmäßigen und ausreichenden Kraftstoffqualitäten gerechnet werden kann. Es ist darauf zu achten, dass nur die jeweils von uns vorgeschriebene Kraftstoffqualität verwendet wird. Der Einsatz von Sekundärzusätzen ist dagegen im Allgemeinen nur eine zusätzliche, nicht erforderliche Kostenbelastung und kann im schlimmsten Fall zu nachhaltigen Schäden führen. In einzelnen Märkten mit schlechter Kraftstoffqualität kann eine zusätzliche Additivierung erforderlich sein. In diesem Sonderfall werden für Mercedes-Benz-Trucks Fahrzeuge erprobte und freigegebene Additive von den Mercedes-Benz-
Trucks Fachwerkstätten empfohlen. Die auf dem Behälter angegebenen Hinweise und Mischungsverhältnisse sind zwingend zu beachten.
Von der Anwendung von Sekundärzusätzen ohne DTFR Freigabe ist dringend abzuraten. Sie erfolgt immer in eigener Verantwortung des Fahrzeugbetreibers, da deren Verwendung die Gewährleistung sowohl des Fahrzeugherstellers- als auch des Kraftstofflieferanten beeinträchtigen kann.`,
					CookieUse: `Die Daimler Truck AG nutzt Cookies für verschiedene Zwecke`,
					PrologAdBlue: `NOx-Reduktionsmittel AUS 32 ("AdBlue") für SCR-Anlagen
Freigegebene NOx-Reduktionsmittel AUS 32("AdBlue")
BB00.40-P-0352-01A
Bei Dieselmotoren hat sich die Technik der Direkteinspritzung des Dieselkraftstoffs nahezu zum Standard entwickelt. Die sehr anspruchsvolle Abgasgesetzgebung macht bei Dieselmotoren eine Abgasnachbehandlung erforderlich. So sind bei zahlreichen modernen PKW-Dieselmotoren heute Oxidationskatalysatoren üblich. Die Einhaltung der neuesten Abgasvorschriften für Personenkraftwagen und leichte Nutzfahrzeuge wird zumindest für die schwereren Fahrzeuge dieser Kategorie den Einsatz weiterer Abgasnachbehandlungssysteme erforderlich machen. Zur Verringerung der NOx-Emission ist eine Reihe von unterschiedlichen Systemen in der Entwicklung bzw. bereits im Einsatz. Bei schweren Nutzfahrzeugen wird als ein System zur Verringerung der Stickoxidemission mit höherer Selektivität der Nox-Katalysator mit Harnstoff als Reduktionsmittel eingesetzt. Hierbei handelt es sich um eine selektive katalytische Nox-Reduktion in Gegenwart von Ammoniak.
Über eine vorgelagerte Reaktionsstufe wird die in den Abgastrakt eingebrachte Harnstoff-Wasserlösung zunächst in Ammoniak überführt. Bei der folgenden katalytischen Reduktion wird das Nox in Gegenwart von Ammoniak weitgehend zu Stickstoff und Wasser zersetzt. Das Harnstoff-SCR-Verfahren für die Abgasnachbehandlung ist Stand der Technik. Eine detaillierte Qualitätsbeschreibung des Reduktionsmittels ist Grundlage für eine flächendeckende, herstellerunabhängige Einführung dieser Technik und erforderlich zur Sicherung der Daueraktivität der Abgasnachbehandlungsanlage. Die Anforderungen für das Reduktionsmittel ist in Gestalt der Normen DIN 70070 (Anforderungen) und DIN V 70071 (Prüfverfahren) veröffentlicht.
Eine Normung auf internationaler Ebene ist abgeschlossen und findet sich in dem Internationalen Standard ISO 22241. Für Mercedes-Benz-Trucks Fahrzeuge ist eine Harnstoffwasserlösung nach den Anforderungen der ISO 22241 einzusetzen. In Europa wird der Markenname "AdBlue®" für diese Qualität häufig verwendet. Bei Verwendung von Qualitäten außerhalb der Spezifikation können Schäden am Bluetec System nicht ausgeschlossen werden.`,
					PrologCoolant: `Korrosions-Frostschutzmittel: 
- gewährleisten Werkstoffverträglichkeit 
- ermöglichen optimale Wärmeabfuhr 
- schützen vor Korrosion 
Die freigegebenen Korrosions-/Frostschutzmittel bzw. Kühlmittel erfüllen die anspruchsvollen Mercedes-Benz-Trucks Spezifikationen. Ein hervorragender Betrieb der Kühlsysteme unter den fahrzeugspezifischen Belastungen ist dadurch gewährleistet. 
Entscheidend ist nicht nur die Wahl des Kühlmittels, sondern auch die Einhaltung der optimalen Wasserqualität und Mischungskonzentration zwischen Konzentrat- und Wasseranteilen während des Betriebs: zu viel Additivpaket hilft so wenig wie zu viel Wasser. 
Im DTFR Blatt 29A100 befinden sich allgemeine Informationen für den korrekten Umgang der Kühlmittel. 
Im DTFR Blatt 29A110 sind alle für die jeweiligen Anwendungen freigegebenen Produkten gelistet.
`,
					PrologMotorOil: `Das Motoröl ist ein für die Funktion und Langlebigkeit des Motors wichtiger Betriebsstoff. Daher wird für die Verwendung in Mercedes-Benz-Trucks Fahrzeugen ausschließlich Motoröle mit DTFR xxxxxx empfohlen. Die DTFR xxxxxx von Produkten ist über die Angabe auf dem Gebinde „DTFR xxxxxx“ (z.B. DTFR 15B120) erkenntlich. In der DTFR 15A100 sind alle für die jeweilige Motorölspezifikation der von der Daimler Truck AG freigegebenen Produkte gelistet.
Die für Mercedes-Benz-Trucks Fahrzeuge und -motoren freigegebenen Motorölspezifikationen können der jeweiligen Fahrzeug-Betriebsanleitung und ggf. ergänzenden Service-Informationen entnommen werden. 
Die abhängig von der Anwendungsregion empfohlene SAE Viskositätsklasse kann dem DTFR 15A110 entnommen werden.`,
					PrologEdac: `Die nachfolgende Produktliste soll Ihnen helfen, den richtigen Betriebsstoff für Ihr Mercedes-Benz Trucks & Buses Fahrzeug/ Aggregat aus der Marktvielfalt auszuwählen.
					
Wir empfehlen, ausschließlich die in der nachfolgenden Liste aufgeführten, von Daimler Truck geprüften und freigegebenen, Produkte zu verwenden.

Der Hochvolt-Luftpresser ist ein öl-geschmiertes Aggregat. Dieses Öl dient zu Schmierung, Wärmeaufnahme und Abführung, sowie Dichtung verschiedenster, interner Funktionsflächen und Prozesse. Daher sollte im Rahmen des Service ein Ölwechsel am Hochvolt-Luftpresser vorgenommen werden.
					`,
					PrologHydrogen: `Die nachfolgende Produktliste soll Ihnen helfen, den richtigen Betriebsstoff für Psr Mercedes-Benz Motors & Buses Fahrzeug/ Aggregat aus der Marktvielfalt auszuwählen.
					
Wir empfehlen, ausschließlich die in der nachfolgenden Liste aufgeführt, von Daimler Truck geprüften und freigegebenen, Produkte zu verwenden.
					`,
					IntroductionContent: `Diese Seite ist eine Veröffentlichungsplattform für die von Mercedes-Benz-Trucks geprüften und freigegebenen Betriebsstoffe für Ihre Fahrzeuge und Aggregate. Generell empfehlen wir ausschließlich Produkte zu verwenden, die von Mercedes-Benz-Trucks geprüft und freigegeben wurden, da: 
- Konstruktionsteile und Betriebsstoffe aufeinander abgestimmt sind, 
- Schäden, die durch die Verwendung ungeeigneter Betriebsstoffe entstehen, nicht unter die Gewährleistung fallen. 
Von Mercedes-Benz-Trucks freigegebene Produkte sind auf den Gebinden eindeutig gekennzeichnet: z.B. „DTFR 15C110 “ 
Die Webseite bildet immer den aktuellen Stand der Betriebsstoff-Vorschriften und der entsprechenden Produktfreigaben im Markt ab.
`,
					Sheet: `DTFR-Blatt`,
					Description: `Beschreibung`,
					Principal: `Auftraggeber`,
					Interested: `Das könnte Sie auch interessieren:`,
					TopCategories: `Unsere Top-Kategorien`,
					BrakeFluid: `Bremsflüssigkeit`,
					Info: `Informationen zu Betriebsstoff-Vorschriften`,
					EngineOil: `Motoröl`,
					Fuel: `Kraftstoff`,
					Getriebeöl: `Getriebeöl`,
					Motoröl: `Motoröl`,
					Bremsflüssigkeit: `Bremsflüssigkeit`,
					Kraftstoff: `Kraftstoff`,
					Fett: `Fett`,
					Hydrauliköl: `Hydrauliköl`,
					Konservierungsmittel: `Konservierungsmittel`,
					Kompressoröl: `Kompressoröl`,
					Lenkgetriebeöl: `Lenkgetriebeöl`,
					Frostschutz: `Frostschutz`,
					Kältemittel: `Kältemittel`,
					GearOil: `Getriebeöl`,
					Grease: `Fett`,
					"E-Drive": `Sistemas de propulsão elétrica`,
					"E-Engine-Oil": `E-Motoröl`,
					"E-Motor-Oil": `E-Motoröl`,
					"edAC-Oil": "edAC-Öl",
					"Axle-Oil": "Óleo do eixo",
					"Axle Oil": "Óleo do eixo",
					HydraulicFluid: `Hydrauliköl`,
					PreservationAgent: `Konservierungsmittel`,
					RefrigeratorOil: `Kompressoröl`,
					SteeringGearOil: `Lenkgetriebeöl`,
					AdBlue: `NOx-Reduktionsmittel`,
					Coolant: `Korrosions- / Frostschutzmittel`,
					Refrigerant: `Kältemittel`,
					Windshield: `Scheibenwaschmittel-Konzentrat`,
					ReservedRights: `© ${new Date().getFullYear()}. Daimler Truck AG. Alle Rechte vorbehalten`,
					PrivacyStatement: `Datenschutz`,
					Cookies: `Cookies`,
					LegalNotice: `Rechtliche Hinweise`,
					Settings: `Einstellungen`,
					Categories: `Categorias`,
					OilFinder: `Öl-Finder`,
					Provider: `Provedor`,
					MappingOverview: `Resumo DTFR`,
					Contact: `Contato`,
					LearnMore: `Mehr erfahren`,
					MbOgOil: `Mercedes-Benz Original-Motoröl`,
					MbChoice: `Wenn ihr Mercedes die Wahl hätte:`,
					OperatingInstructions: `Digitale Betriebsanleitungen`,
					OperatingInstructionsContent: `In den Online-Bedienungsanleitung finden Sie die zu Ihrem Fahr-zeug/Aggregat passenden Betriebsstoff-Spezifikationen`,
					ContactUrl: `https://www.mbcac.net/go/appsupport/Contact.aspx?app=Mercedes-Benz%20BeVo&lang=de-DE`,
					Type30: '30',
					Type40: '40',
					Type50: '50',
					Type80: '80',
					Type90: '90',
					Type0W: '0W',
					Type0W20: '0W-20',
					Type0W30: '0W-30',
					Type0W40: '0W-40',
					Type5W30: '5W-30',
					Type5W40: '5W-40',
					Type5W50: '5W-50',
					Type10W: '10W',
					Type10W30: '10W-30',
					Type10W40: '10W-40',
					Type20W20: '20W-20',
					Type20W50: '20W-50',
					Type75W: '75W',
					Type75W80: '75W-80',
					Type75W85: '75W-85',
					Type75W90: '75W-90',
					Type80W: '80W',
					Type80W90: '80W-90',
					Type80W85: '80W-85',
					Type85W90: '85W-90',
				},
			},
		},
		fallbackLng: 'en',
		debug: true,

		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
