import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {emptyProductSheetObject, IProductSheet} from "../../../interfaces/IProductSheet";
import {getSheetByNumber} from "../../../utility/FetchAPI";
import {CATEGORY_PROPS} from "../../../globals/Constants";
import {setCategoryString, showPdfInNewTab} from "../../../utility/Helper";
import {useTranslation} from "react-i18next";


export const SheetPdf = () => {
    let navigate = useNavigate();
    const {i18n} = useTranslation();
    let {sheetNumber, language} = useParams<{
        sheetNumber: string;
        language: string;
    }>();
    const [sheet, setSheet] = useState<IProductSheet>(emptyProductSheetObject);
    useEffect(() => {
        if (sheetNumber != null) {
            getSheetByNumber(sheetNumber).then((results: any) => {
                showPdfInNewTab(results.data, i18n.language, navigate, false);
            });
        }
    }, [sheetNumber, language]);

    return (<></>);
}