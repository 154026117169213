import { IProduct, emptyProductObject } from './IProduct';

export interface IProductSheet {
  sheetNumber: string;
  oldSheetNumber?: string;
  title: string;
  productCollection: IProduct[];
  category: string;
  titleDe: string;
  titleEn: string;
  htmlDe: string;
  htmlEn: string;
  pdfEn: string;
  pdfDe: string;
  additionalInfoDe: string;
  additionalInfoEn: string;
  publishDate: Date|string;
  privateLabels: Record<string, string>;
}

export const emptyProductSheetObject = {
  sheetNumber: '',
  title: '',
  productCollection: [emptyProductObject],
  category: '',
  titleDe: '',
  titleEn: '',
  htmlDe: '',
  htmlEn: '',
  pdfEn: '',
  pdfDe: '',
  additionalInfoDe: '',
  additionalInfoEn: '',
  publishDate: new Date(),
  privateLabels: {},
};


export interface IColumn {
  key: keyof IProduct;
  check: (row: IProduct) => boolean;
  isLink?: boolean;
}