import {IProductSheet} from '../interfaces/IProductSheet';
import {closeCategoriesMenu, closeSearch} from './DialogHelper';
import {parse} from 'date-format-parse';

export const parseDate = (dateString: string | Date): Date => {
    if (dateString instanceof Date) {
        return dateString
    }
    if (dateString.includes('/')) {
        return parse(
            dateString,
            'M/D/YY, h:m A'
        )
    }

    return parse(
        dateString.replaceAll(',', ''),
        'DD.MM.YY HH:mm'
    )
}

export const setCategoryString = (category: string) => {
    if (category === 'Engine Oil' || category === 'EngineOil') {
        return 'Motoröl';
    } else if (category === 'Brake Fluid' || category === 'BrakeFluid') {
        return 'Bremsflüssigkeit';
    } else if (category === 'Fuel') {
        return 'Kraftstoff';
    } else if (category === 'Gear Oil' || category === 'GearOil') {
        return 'Getriebeöl';
    } else if (category === 'E-Drive') {
        return 'E-Antriebssysteme';
    } else if (category === 'Grease') {
        return 'Fett';
    } else if (category === 'Hydraulic Fluid' || category === 'HydraulicFluid') {
        return 'Hydrauliköl';
    } else if (
        category === 'Preservation Agent' ||
        category === 'PreservationAgent'
    ) {
        return 'Konservierungsmittel';
    } else if (
        category === 'Refrigerator Oil' ||
        category === 'RefrigeratorOil'
    ) {
        return 'Kompressoröl';
    } else if (
        category === 'Steering Gear Oil' ||
        category === 'SteeringGearOil'
    ) {
        return 'Lenkgetriebeöl';
    } else if (
        category === 'NOx Reducing Agent' ||
        category === 'NOxReducingAgent' ||
        category === 'AdBlue'
    ) {
        return 'NOx-Reduktionsmittel';
    } else if (
        category === 'Korrosions-/Frostschutz' ||
        category === 'Korrosions- /Frostschutz' ||
        category === 'Korrosions- / Frostschutzmittel'
    ) {
        return 'Frostschutz';
    } else if (category === 'Coolant') {
        return 'Frostschutz';
    } else if (category === 'Refrigerant') {
        return 'Kältemittel';
    } else if (category === 'edAC-Oil') {
        return 'edAC-Öl';
    } else if (
        category === 'E-Engine Oil' ||
        category === 'E-Motor-Oil'

    ) {
        return 'E-Motoröl';
    } else if (category === 'Axle Oil') {
        return 'Achsenöl';
    } else if (
        category === 'Windshield Washer Fluid Concentrate' ||
        category === 'WindshieldWasherFluidConcentrate' ||
        category === 'Scheibenwaschmittel-Konzentrat' ||
        category === 'Windshield'
    ) {
        return 'Scheibenwaschmittel-Konzentrat';
    } else return category;
};

export function showPdfInNewTab(
    sheet: IProductSheet,
    language: string,
    navigate: any,
    newWindow: boolean = true
) {
    let path = '/';
    if (sheet?.pdfDe != null && sheet?.pdfEn != null) {
        let fileName;
        let base64Data;
        if (language == 'de') {
            fileName = sheet.titleDe;
            base64Data = sheet.pdfDe;
        } else {
            fileName = sheet.titleEn;
            base64Data = sheet.pdfEn;
        }
        const linkSource = `data:application/pdf;base64,${encodeURI(base64Data)}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        if (!navigator.userAgent.toLowerCase().includes('android')) {
            let pdfWindow = newWindow ? window?.open(linkSource) : window;
            pdfWindow?.document.write(
                '<html<head><title>' +
                fileName +
                '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>'
            );
            pdfWindow?.document.write(
                "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(base64Data) +
                "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>"
            );
            pdfWindow?.history.pushState({}, fileName, `/pdf/${sheet.sheetNumber}/${language}`);
        } else {
            downloadLink.click();
        }
    } else {
        if (sheet != null) {
            if (language == 'de') {
                path = '/sheet/' + sheet?.sheetNumber.replaceAll(' ', '_') + '/de';
            } else {
                path = '/sheet/' + sheet?.sheetNumber.replaceAll(' ', '_') + '/en';
            }
            closeCategoriesMenu();
            closeSearch();
            navigate(`${path}`);
        }

        //window.location.reload();
    }
}
