import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import './NavWeb.css';
import '../../../../../globals/style/GlobalStyle.css';
import { useTranslation, Trans } from 'react-i18next';
import {
  getSheetsByCategory,
  getAllSheets,
  getAllData,
  getSheetNumbersByCategory,
} from '../../../../../utility/FetchAPI';
import { CATEGORY_PROPS } from '../../../../../globals/Constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { showPdfInNewTab } from '../../../../../utility/Helper';
import {
  closeCategoriesMenu,
  closeSubCategoriesMenu,
  openSubCategoriesMenu,
} from '../../../../../utility/DialogHelper';

import { WbIcon } from '@workbench/react';

// Styling

/**
 * Interactive car image component for the main page.
 *
 * @returns
 */
export const NavWeb = () => {
  const { t, i18n } = useTranslation();
  const [categorySheets, setCategorySheets] = useState();
  const [ChoosenCategory, setChoosenCategory] = useState();
  const [categoryLink, setCategoryLink] = useState('');
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const storedCategories = localStorage.getItem('categorySheets');
    const lastUpdated = localStorage.getItem('categoriesLastUpdated');
    const now = new Date().getTime();
  
    if (storedCategories && lastUpdated && (now - lastUpdated < 60 * 60 * 1000)) {
      setCategorySheets(JSON.parse(storedCategories));
    } else {
      fetchCategoriesFromAPI();
      localStorage.setItem('categoriesLastUpdated', now);
    }
  }, []);  

  // const fetchCategoriesFromAPI = () => {
  //   let categorzMap = new Map();
  //   getAllData().then((result) => {


  //     const sortedSheets = Object.keys(result.data).map(categoryKey => {
  //       return {
  //         category: categoryKey,  
  //         sheets: result.data[categoryKey].sort((itemA, itemB) => {
  //           const sheetNumberA = itemA.sheetNumber;
  //           const sheetNumberB = itemB.sheetNumber;
  //           if(sheetNumberA.startsWith('DTFR') && !sheetNumberB.startsWith('DTFR')) {
  //             return -1;
  //           }
  //           if(!sheetNumberA.startsWith('DTFR') && sheetNumberB.startsWith('DTFR')) {
  //             return 1;
  //           }
  //           return sheetNumberA.localeCompare(sheetNumberB);
  //         })
  //       };
  //     });
  //     sortedSheets.forEach((category) => {
  //       categorzMap.set(category.category, JSON.stringify(category.sheets));
  //     });
  //     const categoryMapArray = Array.from(categorzMap.entries());
  //     localStorage.setItem('categorySheetsMap', JSON.stringify(categoryMapArray));
  //     setCategorySheets(sortedSheets);
  //   });
  // };


  const fetchCategoriesFromAPI = () => {
    let categorzMap = new Map();
    
    getAllData().then((result) => {
        // Initialize a map to store the grouped data by category
        let categoryGroups = {};

        // Group sheets by their category
        result.data.forEach((sheet) => {
            const category = sheet.category;
            
            // Initialize the array for this category if it doesn't exist
            if (!categoryGroups[category]) {
                categoryGroups[category] = [];
            }

            const newSheet = {
                sheetNumber: sheet.sheetNumber,
                pdfDe: sheet.pdfDe,
                pdfEn: sheet.pdfEn,
                titleDe: sheet.titleDe,
                titleEn: sheet.titleEn, 
                htmlDe: sheet.htmlDe,
                htmlEn: sheet.htmlEn,
            };

            // Push the current sheet into the corresponding category group
            categoryGroups[category].push(newSheet);
        });

        // Sort the sheets within each category
        Object.keys(categoryGroups).forEach(categoryKey => {
            const sheets = categoryGroups[categoryKey];
            
            // Sort the sheets by sheetNumber or apply your custom sorting logic
            categoryGroups[categoryKey] = sheets.sort((itemA, itemB) => {
                const sheetNumberA = itemA.sheetNumber;
                const sheetNumberB = itemB.sheetNumber;

                if (sheetNumberA.startsWith('DTFR') && !sheetNumberB.startsWith('DTFR')) {
                    return -1;
                }
                if (!sheetNumberA.startsWith('DTFR') && sheetNumberB.startsWith('DTFR')) {
                    return 1;
                }
                return sheetNumberA.localeCompare(sheetNumberB);
            });
        });

        // Save the sorted categories in a map and local storage
        Object.keys(categoryGroups).forEach((categoryKey) => {
            categorzMap.set(categoryKey, JSON.stringify(categoryGroups[categoryKey]));
        });

        const categoryMapArray = Array.from(categorzMap.entries());
        localStorage.setItem('categorySheetsMap', JSON.stringify(categoryMapArray));
        
        // Set state with the sorted category data
        const sortedSheets = Object.keys(categoryGroups).map(categoryKey => ({
            category: categoryKey,
            sheets: categoryGroups[categoryKey]
        }));
        setCategorySheets(sortedSheets);
    });
};

  

  const chooseCategory = (category) => {
    const categoryMapArray = JSON.parse(localStorage.getItem('categorySheetsMap'));
    const categorzMap = new Map(categoryMapArray);
    const categoryName = t(category.description, { lng: 'de' });
    let foundCategory = null;
    setChoosenCategory(category);
    if(categorzMap.get(categoryName)) {
      foundCategory = categorzMap.get(categoryName);
      setCategorySheets(JSON.parse(foundCategory));
    }

    if(!foundCategory) {
      // closeSubCategoriesMenu();
      setCategorySheets(null);
    }
    openSubCategoriesMenu();
};

  const openLearnMore = (categoryLink) => {
    i18n.language == 'de'
      ? navigate(`/category${categoryLink}/de`)
      : navigate(`/category${categoryLink}/en`);
    closeCategoriesMenu();
  };

  return (
    <div id='mainCategoryMenu' className='nav-web-wrapper notVisible'>
      <nav
        class='wb-header-flyout-container wb-header-flyout-container--open'
        aria-label='Sub Navigations'
      >
        {/* <div class='wb-header-flyout-container__close-layer'></div> */}
        <div class='wb-header-flyout-container__inner'>
          <div className='nav-web-parent'>
            <wb-header-flyout level='2' open id='visibleMenu'>
              <div class='wb-header-flyout__body'>
                <div class='wb-header-flyout__topbar'>
                  <div class='wb-header-flyout__topbar-outer'>
                    <div class='wb-header-flyout__topbar-inner'>
                      <button
                        class='wb-header-flyout__control wb-header-flyout__control--icon-only wb-header-flyout__back-arrow'
                        type='button'
                        onClick={closeCategoriesMenu}
                      >
                        <wb-icon name='chevron-left' flip-rtl></wb-icon>
                      </button>
                      <h3 class='wb-header-flyout__topbar-headline menuTextDesktop'>
                        {t('Categories')}
                      </h3>
                      <button
                        class='wb-header-flyout__control wb-header-flyout__control--icon-only wb-header-flyout__x-button'
                        type='button'
                        onClick={closeCategoriesMenu}
                      >
                        <wb-icon name='close'></wb-icon>
                      </button>
                      <hr class='wb-header-flyout__topbar-divider' />
                    </div>
                  </div>
                </div>
                <div class='wb-header-flyout__nav'>
                  <ul class='wb-header-flyout__nav-items'>
                    {CATEGORY_PROPS.map((category, i, arr) => {
                      return (
                        <li>
                          <button
                            class='wb-header-flyout__nav-link'
                            onClick={(e) => (
                              e.preventDefault,
                              chooseCategory(category),
                              setCategoryLink(category.link)
                            )}
                          >
                            <span class='wb-header-flyout__nav-item'>
                              <WbIcon
                                className='wb-header-flyout__nav-item-icon'
                                src={category.icon}
                              />
                              <span
                                class={clsx(
                                  'wb-header-flyout__nav-item-label',
                                  { ['lastMenuItem']: arr.length - 1 === i }
                                )}
                              >
                                {t(category.description)}
                              </span>
                              <span class='wb-header-flyout__control wb-header-flyout__control--icon-only'>
                                <wb-icon
                                  name='chevron-right'
                                  flip-rtl
                                ></wb-icon>
                              </span>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </wb-header-flyout>
          </div>

          <div id='subCategoryMenu' className='nav-web-child notVisible'>
            <wb-header-flyout level='1' open>
              <div class='wb-header-flyout__body childDialog'>
                <div class='wb-header-flyout__topbar '>
                  <div class='wb-header-flyout__topbar-outer childDialogContainer'>
                    <div class='wb-header-flyout__topbar-inner '>
                      <button
                        class='wb-header-flyout__control wb-header-flyout__control--icon-only wb-header-flyout__back-arrow'
                        type='button'
                        onClick={closeSubCategoriesMenu}
                      >
                        <wb-icon name='chevron-left' flip-rtl></wb-icon>
                      </button>
                      <h3 class='wb-header-flyout__topbar-headline'>
                        {ChoosenCategory ? (
                          <WbIcon
                            className='wb-header-flyout__topbar-headline-icon'
                            src={ChoosenCategory.icon}
                          />
                        ) : (
                          ''
                        )}
                        {ChoosenCategory ? t(ChoosenCategory.description) : ''}
                      </h3>
                      <button
                        class='wb-header-flyout__control wb-header-flyout__control--icon-only wb-header-flyout__x-button'
                        type='button'
                        onClick={closeCategoriesMenu}
                      >
                        <wb-icon name='close'></wb-icon>
                      </button>
                      <hr class='wb-header-flyout__topbar-divider' />
                    </div>
                  </div>
                </div>
                <div class='wb-header-flyout__nav '>
                  <ul class='wb-header-flyout__nav-items childNav'>
                    <li class='desktopMarginChevron'>
                      <div
                        class='wb-header-flyout__nav-link'
                        onClick={() => openLearnMore(categoryLink)}
                        //to={(path(product))}
                      >
                        <span class='wb-header-flyout__nav-item'>
                          <span class='wb-header-flyout__nav-item-label'>
                            {t('LearnMore')}
                          </span>
                        </span>
                      </div>
                    </li>
                    {categorySheets?.map((product) => {
                      return (
                        <li class='desktopMarginChevron'>
                          <div
                            class='wb-header-flyout__nav-link'
                            onClick={() =>
                              showPdfInNewTab(product, i18n.language, navigate)
                            }
                            //to={(path(product))}
                          >
                            <span class='wb-header-flyout__nav-item'>
                              <span class='wb-header-flyout__nav-item-label'>
                                {product.sheetNumber}
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </wb-header-flyout>
          </div>
        </div>
      </nav>
    </div>
  );
};
