import './CookieModel.css';
import React, { useEffect } from 'react';
import { useTranslation, i18n } from 'react-i18next';
import { PrivacyStatementDe } from './text_DE/PrivacyStatementDe';
import { PrivacyStatementEn } from './text_EN/PrivacyStatementEn';
import { ProviderDe } from './text_DE/ProviderDe';
import { ProviderEn } from './text_EN/ProviderEn';
import { LegalNoticeDe } from './text_DE/LegalNoticeDe';
import { LegalNoticeEn } from './text_EN/LegalNoticeEn';
import { CookiesDe } from './text_DE/CookiesDe';
import { CookiesEn } from './text_EN/CookiesEn';
import { getViewportWidth } from '@workbench/core';

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export default function CookieModel() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Update the document title using the browser API
    const modal = document.querySelector('wb-modal');

    modal.addEventListener('wbclose', function () {
      document.getElementById('cookieModelContainer').classList.add('hide');
    });
  }, []);

  return (
    <div id='cookieModelContainer' className='wb-grid-container hide'>
      <div className='wb-grid-row cstm-cookie-model'>
        <wb-modal
          mode='info'
          className='cstm-txt'
          closeOnBackdropClick
          closeOnEscapeKey
        >
          <wb-modal-header></wb-modal-header>
          <wb-modal-content headline=' '>
            <wb-tabs id='tabs' selected='settings'>
              {getViewportWidth() > 767 && (
                <wb-tab-bar fitted>
                  <wb-tab id='provider' name='provider'>
                    {t('Provider')}
                  </wb-tab>
                  <wb-tab id='legalNotice' name='legalNotice'>
                    {t('LegalNotice')}
                  </wb-tab>
                  {/*<wb-tab id='settings' name='settings' selected>*/}
                  {/*  {t('Settings')}*/}
                  {/*</wb-tab>*/}
                  <wb-tab id='dataPrivacy' name='dataPrivacy'>
                    {t('PrivacyStatement')}
                  </wb-tab>
                </wb-tab-bar>
              )}

              {getViewportWidth() < 768 && (
                <wb-tab-bar>
                  <wb-tab id='provider' name='provider'>
                    {t('Provider')}
                  </wb-tab>
                  <wb-tab id='legalNotice' name='legalNotice'>
                    {t('LegalNotice')}
                  </wb-tab>
                  <wb-tab id='settings' name='settings' selected>
                    {t('Settings')}
                  </wb-tab>
                  <wb-tab id='dataPrivacy' name='dataPrivacy'>
                    {t('PrivacyStatement')}
                  </wb-tab>
                </wb-tab-bar>
              )}

              <wb-tab-content id='providerTabContent' name='provider'>
                {i18n.language === 'de' ? (
                  <ProviderDe></ProviderDe>
                ) : (
                  <ProviderEn></ProviderEn>
                )}
              </wb-tab-content>

              <wb-tab-content id='legalNoticeTabContent' name='legalNotice'>
                {i18n.language === 'de' ? (
                  <LegalNoticeDe></LegalNoticeDe>
                ) : (
                  <LegalNoticeEn></LegalNoticeEn>
                )}
              </wb-tab-content>

              {/*<wb-tab-content id='settingsTabContent' name='settings'>*/}
              {/*  {i18n.language === 'de' ? (*/}
              {/*    <CookiesDe></CookiesDe>*/}
              {/*  ) : (*/}
              {/*    <CookiesEn></CookiesEn>*/}
              {/*  )}*/}
              {/*</wb-tab-content>*/}

              <wb-tab-content id='dataPrivacyTabContent' name='dataPrivacy'>
                {i18n.language === 'de' ? (
                  <PrivacyStatementDe></PrivacyStatementDe>
                ) : (
                  <PrivacyStatementEn></PrivacyStatementEn>
                )}
              </wb-tab-content>
            </wb-tabs>
          </wb-modal-content>
        </wb-modal>
      </div>
    </div>
  );
}
