//Module Import
import {useTranslation, Trans} from 'react-i18next';

//Icons
import greaseIcon from '../resource/icons/ic_bevo_fett_48.svg';
import brakeFluidIcon from '../resource/icons/ic_bevo_breaking-fluid_48.svg';
import fuelIcon from '../resource/icons/ic_bevo_fuel-comp_48.svg';
import hydraulicOilIcon from '../resource/icons/ic_bevo_hydraulikoel_48.svg';
import infoIcon from '../resource/icons/ic_bevo_info_48.svg';
// import stearingGearIcon from '../resource/icons/
import axleOilIcon from '../resource/icons/ic_bevo_lenkgetriebe_48.svg';
import stearingGearIcon from '../resource/icons/Lenkgetriebe.svg';
import adBlueIcon from '../resource/icons/ic_bevo_NOx-Reduktionsmittel_48.svg';
import engineOilIcon from '../resource/icons/ic_bevo_motorEngine.svg';
import washerFluidIcon from '../resource/icons/ic_bevo_washer-fluid_48.svg';
import refrigeratorOilIcon from '../resource/icons/ic_bevo_kompressor_48.svg';
import transmissionIcon from '../resource/icons/ic_bevo_transmission_48.svg';
import preservationIcon from '../resource/icons/ic_bevo_konservierungsstoffe_48.svg';
import coolantIcon from '../resource/icons/ic_bevo_korrosions-frostschutz_48.svg';
import refrigerantIcon from '../resource/icons/ic_bevo_kaeltemittel_48.svg';
import electricEngineIcon from '../resource/icons/E-Motor.svg';
import edacIcon from '../resource/icons/EdAc.svg';
import hydrogenIcon from '../resource/icons/hydrogen.svg';

// Categoty header images
import adBlueHeader from '../resource/image/adBlueHeader.jpg';
import categoryHeader from '../resource/image/categoryHeader.jpg';
import HydrogenHeader from '../resource/image/HydrogenHeader.jpg';
import GetriebeHeader from '../resource/image/GetriebeHeader.jpg';
import KühlantHeader from '../resource/image/KühlantHeader.jpg';
import MotorHeader from '../resource/image/MotorHeader.jpg';

// Media Query breakpoint
export const MEDIA_QUERY_BREAKPOINT_TABLE_NUMBER = 900;
export const MEDIA_QUERY_BREAKPOINT = '(max-width: 720px)';
export const MEDIA_QUERY_BREAKPOINT_TABLE = `(max-width: ${MEDIA_QUERY_BREAKPOINT_TABLE_NUMBER}px)`;

// API
export const BASE_URL =
    process.env.NODE_ENV == 'production'
        ? '/api/v1/'
        : 'http://localhost:8085/api/v1/';

// export const BASE_URL = 'http://localhost:8080/api/v1/';
// export const BASE_URL = 'https://bevo-dev.trucks.app.corpintra.net/api/v1/';

// Categories
export const CATEGORY_PROPS = [
    /* {
      icon: infoIcon,
      image: categoryHeader,
      description: 'Info',
      link: '/general-information',
    }, */
    {
        icon: engineOilIcon,
        image: MotorHeader,
        description: 'EngineOil',
        link: '/engine-oil',
        sheetPrefix: 15,
    },
    {
        icon: brakeFluidIcon,
        image: categoryHeader,
        description: 'BrakeFluid',
        link: '/brake-fluid',
        sheetPrefix: 30,
    },
    {
        icon: fuelIcon,
        image: categoryHeader,
        description: 'Fuel',
        link: '/fuel',
        sheetPrefix: 16,
    },
    {
        icon: transmissionIcon,
        image: GetriebeHeader,
        description: 'GearOil',
        link: '/gear-oil',
        sheetPrefix: 13,
    },
    {
        icon: greaseIcon,
        image: categoryHeader,
        description: 'Grease',
        link: '/grease',
        sheetPrefix: 33,
    },
    {
        icon: hydraulicOilIcon,
        image: categoryHeader,
        description: 'HydraulicFluid',
        link: '/hydraulic-fluid',
        sheetPrefix: 31,
    },
    {
        icon: preservationIcon,
        image: categoryHeader,
        description: 'PreservationAgent',
        link: '/preservation-agent',
        sheetPrefix: 34,
    },
    {
        icon: refrigeratorOilIcon,
        image: categoryHeader,
        description: 'RefrigeratorOil',
        link: '/compressor-oil',
        sheetPrefix: 36,
    },
    {
        icon: stearingGearIcon,
        image: categoryHeader,
        description: 'SteeringGearOil',
        link: '/stearing-gear-oil',
        sheetPrefix: 38,
    },
    {
        icon: adBlueIcon,
        image: adBlueHeader,
        description: 'AdBlue',
        link: '/NOx-reducing-agent',
        sheetPrefix: 17,
    },
    {
        icon: coolantIcon,
        image: KühlantHeader,
        description: 'Coolant',
        link: '/coolant',
        sheetPrefix: 29,
    },
    {
        icon: refrigerantIcon,
        image: categoryHeader,
        description: 'Refrigerant',
        link: '/refrigerant',
        sheetPrefix: 35,
    },
    {
        icon: washerFluidIcon,
        image: categoryHeader,
        description: 'Windshield',
        link: '/windshield-washer-fluid-concentrate',
        sheetPrefix: 38,
    },
    {
        icon: edacIcon,
        image: categoryHeader,
        description: 'edAC-Oil',
        link: '/edAC-Oil',
        sheetPrefix: 32,
    },
    {
        icon: electricEngineIcon,
        image: categoryHeader,
        description: 'E-Drive',
        link: '/E-Drive',
        sheetPrefix: 10,
    },
    {
        icon: axleOilIcon,
        image: categoryHeader,
        description: 'Axle-Oil',
        link: '/Axle-Oil',
        sheetPrefix: 11,
    },
    // {
    //     icon: hydrogenIcon,
    //     image: HydrogenHeader,
    //     description: 'Hydrogen',
    //     link: '/Hydrogen',
    //     sheetPrefix: 39,
    // },
].sort((a,b) => a.sheetPrefix  - b.sheetPrefix);

export const TABLE_HEADER: {
    [key: string]: { key: string; value: string };
} = {
    productName: {key: 'productName', value: 'ProductName'},
    description: {key: 'description', value: 'Principal'},
    type30: {key: 'type30', value: '30'},
    type40: {key: 'type40', value: '40'},
    type50: {key: 'type50', value: '50'},
    type80: {key: 'type80', value: '80'},
    type90: {key: 'type90', value: '90'},
    type0W: {key: 'type0W', value: '0W'},
    type0W20: {key: 'type0W20', value: '0W-20'},
    type0W30: {key: 'type0W30', value: '0W-30'},
    type0W40: {key: 'type0W40', value: '0W-40'},
    type5W30: {key: 'type5W30', value: '5W-30'},
    type5W40: {key: 'type5W40', value: '5W-40'},
    type5W50: {key: 'type5W50', value: '5W-50'},
    type10W: {key: 'type10W', value: '10W'},
    type10W30: {key: 'type10W30', value: '10W-30'},
    type10W40: {key: 'type10W40', value: '10W-40'},

    type10W60: {key: 'type10W60', value: '10W-60'},
    type15W40: {key: 'type15W40', value: '15W-40'},
    type20W20: {key: 'type20W20', value: '20W-20'},
    type20W50: {key: 'type20W50', value: '20W-50'},
    type75W: {key: 'type75W', value: '75W'},
    type75W80: {key: 'type75W80', value: '75W-80'},
    type75W85: {key: 'type75W85', value: '75W-85'},
    type75W90: {key: 'type75W90', value: '75W-90'},
    type80W: {key: 'type80W', value: '80W'},
    type80W90: {key: 'type80W90', value: '80W-90'},
    type80W85: {key: 'type80W85', value: '80W-85'},
    type85W90: {key: 'type85W90', value: '85W-90'},
};
